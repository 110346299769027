<script setup lang="ts">
import type { UITitleStripProps } from './UITitleStrip.props'
const props = defineProps<UITitleStripProps>()

const { isGiorgioArmaniTheme } = await useTheme()

const defineTextAlignment = computed(() => {
  switch (props.textAlignment) {
    case 'align-class-right':
      return (props.links?.length ?? 0) > 0
        ? 'flex-1 text-right pr-3'
        : 'flex-1 text-right'
    case 'align-class-left':
      return 'flex-1 text-left'
    case 'align-class-center':
      return 'flex-1 text-center'
    default:
      return ''
  }
})
</script>

<template>
  <div
    :class="
      customClass
        ? customClass
        : 'lg:py-xl gap-md positive-padding md:py-lg py-md text-text-primary flex w-full flex-col'
    "
  >
    <div class="gap-sm flex flex-col">
      <div class="flex w-full items-center justify-between">
        <div
          v-if="title"
          :class="[
            'text-text-primary markdown-title-style uppercase',
            textAlignment ? defineTextAlignment : '',
            isGiorgioArmaniTheme
              ? 'md:text-light-3 text-light-5 lg:text-light-1'
              : 'md:text-book-3 text-book-4 lg:text-book-1',
          ]"
        >
          <UtilsMarkdown container="p" :content="title" />
        </div>
        <div v-if="links?.length" class="hidden gap-2 md:flex xl:gap-4">
          <UILink
            v-for="link in links"
            :key="link?.label"
            class="text-text-primary text-link-6 md:text-link-5 shrink-0"
            v-bind="link"
          />
        </div>
      </div>
      <div
        v-if="description"
        class="text-text-primary text-link-6 md:text-light-5 markdown-description-style"
      >
        <UtilsMarkdown
          container="p"
          :content="description"
          class="text-text-primary"
        />
      </div>
    </div>
    <div v-if="links?.length" class="flex gap-2 md:hidden">
      <div
        v-for="link in links"
        :key="link?.label"
        class="text-text-primary text-link-6 md:text-link-5 shrink-0"
      >
        <UILink v-bind="link" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.markdown-title-style :deep(strong) {
  @apply text-text-primary text-medium-3 md:text-medium-2 lg:text-medium-1;
}
.markdown-description-style :deep(strong) {
  @apply text-text-primary text-medium-6 md:text-medium-5;
}
</style>
